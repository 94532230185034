import React, { useState } from "react";
import "./AnimatedBackground.css"
import AnimatedBackground from "../../Components/AnimatedBackgroundShowcase/AnimatedBackground";
import nightsky from "../../Animation/nightsky";
import Header from "../../Components/Header/Header";
import { MathJax } from "better-react-mathjax";
import CanvasCode from "../../CodeDisplays/CanvasCode";
import AnimatedBackgroundCode from "../../CodeDisplays/AnimatedBackgroundCode";
import NightskyCode from "../../CodeDisplays/nightskycode";
import Footer from "../../Components/Footer/Footer";

const AnimatedBackgroundPage=(props)=>{
    
    const [contentHidden,hideContent] = useState(false)
    
    return(
        <div class="abs">
        <Header hideContent={hideContent} contentHidden={contentHidden} />
        <AnimatedBackground animation={nightsky} 
        content={ 
            <main style={{visibility:contentHidden?"hidden":"visible"}}>  
                <section>
                    <h1 className="header">AnimatedBackground</h1>
                    <h2 className="subheader">A Component for What's Behind a Webpage.</h2>
                    <p className=" indent plain"> Out of all the ways to make a webpage more visually appealing, animation makes the biggest immediate impression (in my opinion). Having a consistent design language and an organized UI are important, and choosing the right colors, fonts, and graphics can give users impressions of professionalism and reliability. But nothing radiates fun and engaging quite like animation.</p>
                    <p className=" indent plain"> My goals in this project were simple. Implement animation, find a way to overlay an entire webpage on top of an animation, and try to get the animation interacting with user input. I wanted to use React.js, mainly because it's my preferred web-dev environment, but also because it’s widely used in the industry. The React community offers a handful of animation libraries, but many of these libraries are overly simplistic and restrictive. So I decided to challenge myself to implement this myself.</p>
                </section>
                <section>
                    <h2 className="subheader">The Canvas</h2>
                    <p className=" indent plain">The canvas element was the first thing that came to my mind when thinking of implementing animation. This element can be manipulated to draw something to the screen using javascript. You can also schedule the canvas to redraw every now and then, essentially achieving a framerate that can be used to create animations. In React, you can access the context of the canvas using a reference, and schedule the window to redraw using useEffect (or componentDidMount).</p>
                    <p className=" indent plain">The simplest solution would have been to create a canvas element on the page I wanted to animate, write the code for the animation in the file for the page, and handle all the rerendering in the code for the web page. There are two problems with this approach:</p>
                    <ol>
                        <li class="plain">This severely diminishes the readability of the code for the web pages, which ought to be kept organized.</li>
                        <li class="plain">This means that wherever I want to include an animation, I need to access the useEffect, declare a reference, add code for the animation, and include a canvas element in the return value. In short, this approach is terrible for reusability.</li>
                    </ol>
                    <p className=" indent plain">The ideal solution is one where I have absolute control over how the animation looks and works, without having to copy and paste a massive amount of code into all my webpages. So I decided to create a “Canvas” component, which takes code for the animation in the props, and handles rerendering and resizing by itself. This way, all I have to do to create an animation with the canvas element, write three lines of code. One for importing the Canvas component, one for rendering the Canvas component, and one for importing the code for drawing each frame (kept on a different file).</p>
                    
                </section>
                <section>
                    <h2 className="subheader">Canvas.js</h2>
                    <CanvasCode/>
                </section>
                <section>
                    <h2 className="subheader">Animated Background</h2>
                    <p className=" indent plain">I now had a component that could be used on its own, and still had the ability to control the animation by providing the code for it via props. All I had to do was put it in my webpages, and give it an absolute position with a zIndex lower than everything else on the page. This solution was simple enough, and realistically wouldn't have taken that much time out of the development of the webpage. But it was not good enough for me.</p>
                    <p className=" indent plain">I wanted to avoid reusing CSS code in this case, for no other reason than because I felt like it. So I decided to create another component called “AnimatedBackground. This component is much simpler than the last one, as it only had to take some content for the page, and display it overlaid on top of the Canvas component. In only 17 lines, I save myself the hassle of rewriting the same CSS code multiple times.</p>
                    <p className=" indent plain">The AnimatedBackground component takes two props, one for the script for the animation and one for the content being displayed by the animation. The Canvas is placed in a container with a fixed position and a zIndex of 0, and the content is placed in a container with a relative position and a zIndex of one. The result is a catch-all component which handles rendering an animated background using the Canvas component, and handles the overlaying of the rest of the webpage as well.</p>
                </section>
                <section>
                    <h2 className="subheader">AnimatedBackground.js</h2>
                    <AnimatedBackgroundCode/>
                </section>
                <section>
                    <h2 className="subheader">Night Sky</h2>
                    <p className=" indent plain">The animation on this screen is called nightsky.js, depicting a moon progressing across the sky over a landscape of some mountains. The progress the moon makes is determined by how far the user has scrolled down the screen. As the moon begins its march, the colors of the scene reflect the colors of dusk. Half way through the night, the colors shift to show a greater contrast between heaven and earth, reflecting the brightness of a moon at midnight. As the user approaches the bottom of the page, the colors shift back to their original values, reflecting the end of the journey and a new dawn.</p>
                    <p className=" indent plain">Enough poetry, let's talk about math. The colors of the mountain and the sky are calculated frame by frame based on the value of a variable called scrollProgress, which stores the percentage of the page the user has scrolled through as a decimal. A function called interpolateColor is used to calculate this value. It takes the initial color, the color for when the user has reached the halfway point of the page, and scrollProgress as parameters. It breaks the hex values for the colors into rgb values, and uses scroll value to determine how far between the start value and the halfway value the current value should be. It returns the color for the given frame, and uses it to draw the scene.</p>
                    <p className=" indent plain">The independent variable for the colors is scrollProgress, but scrollProgress also determines the position of the moon. The moon is a radial gradient whose center is changed every frame based off of scrollProgress. The x position of the moon is given by</p>
                    <MathJax config={{ "HTML-CSS": { fonts: [ "sana-Math"], scale: 1 }}} className="equation">{"$$x=(window.innerWidth)*srollProgress$$"}</MathJax>
                    <p className="plain">meaning that the percentage of the page the user has scrolled is also the percentage of the width of the page that the moon has traversed horizontally.</p>
                    <p className=" indent plain">The height of the moon is a little more complicated. I needed to give the impression that the moon was rising and setting, meaning I needed nonlinearity. I decided that an ellipse best represented this motion, so the Y position is given by</p>
                    <MathJax className="equation">{"$$\\theta=-(\\pi)*srollProgress$$"}</MathJax>
                    <MathJax className="equation">{"$$y=\\frac{window.innerHeight}{2}+\\frac{window.innerHeight}{6}*\\sin{\\theta}$$"}</MathJax>
                    <p className="plain">The initial value for Y is half of the window height, meaning the moon starts halfway up the screen. At its maximum height, the moon rises ⅙th of the screen height, which reflects the radius of the ellipse on the y axis.</p>
                </section>
                <section>
                    <h2 className="subheader">nightsky.js</h2>
                    <NightskyCode/>
                </section>
                {/* <MathJax>{"\\(\\frac{10}{4x} \\approx 2^{12}\\)"}</MathJax> */}
            </main>
        }
        
         />
         <Footer/>
        </div>
    );
}
export default  AnimatedBackgroundPage;