import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { synthwave84 } from 'react-syntax-highlighter/dist/esm/styles/prism';

function AnimatedBackgroundCode() {
  const codeString = `
    import React from "react";
    import Canvas from "../Canvas/Canvas";

    const AnimatedBackground=(props)=>{
        const {content,animation} = props
        return(
            <div>
                <div id='scrollable_content' style={{position:"relative",zIndex:1,overflow:'hidden',height:'auto'}}>
                    {content}
                </div>
                <div   style={{position:"fixed",zIndex:0, height:'100vh',width:'100vw', overflow:'hidden',top:0,left:0}}>
                    <Canvas draw={animation}/>
                </div>
            </div>
        );
    }
    export default  AnimatedBackground;
`;

  return (
    <SyntaxHighlighter language="javascript" style={synthwave84}  customStyle={{ fontSize: '0.75vw' }}>
      {codeString}
    </SyntaxHighlighter>
  );
}

export default AnimatedBackgroundCode;