import React from "react";
import Canvas from "../Canvas/Canvas";

const AnimatedBackground=(props)=>{
    const {content,animation} = props
    return(
        <div>
            <div id='scrollable_content' style={{position:"relative",zIndex:1,overflow:'hidden',height:'auto'}}>
                {content}
            </div>
            <div   style={{position:"fixed",zIndex:0, height:'100vh',width:'100vw', overflow:'hidden',top:0,left:0}}>
                <Canvas draw={animation}/>
            </div>
        </div>
    );
}
export default  AnimatedBackground;