import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { synthwave84 } from 'react-syntax-highlighter/dist/esm/styles/prism';

function CanvasCode() {
  const codeString = `
    import React, { useRef, useEffect } from 'react'

    /**
     * Canvas
     * @param {Component} props 
     * @returns canvas element
     * 
     * This is a component that takes in a "draw" function, which defines a 2d animation
     * using the context of the canvase element. I'm setting this up so I can pass it animation
     * as a prop, allowing for reuse of the component for different animation.
     * 
     * This component is an adaptation of Lucas Miranda's Canvas component, which you can find
     * here: https://medium.com/@pdx.lucasm/canvas-with-react-js-32e133c05258
     * 
     */
    const Canvas = props => {
    //pull the draw function from props
    const { draw, ...rest } = props

    //declare a reference for the canvas element.
    const canvasRef = useRef(null)
    
    useEffect(() => {
        //get canvas context
        const canvas = canvasRef.current
        const context = canvas.getContext('2d')
        let frameCount = 0
        let animationFrameId

        //set width and height to the dimensions of th parent element of the canvas
        canvas.width=canvas.parentElement.clientWidth;
        canvas.height=canvas.parentElement.clientHeight;
    
        //increments frame count and schedules the next frame to be drawn
        const render = () => {
        frameCount++
        draw(context, frameCount)
        //we store the id of the next frame in case we have to cancel the animation
        animationFrameId = window.requestAnimationFrame(render)
        }
        //initial render
        render()
        
        //resizes the canvas element to match the parent's dimensions
        const handleResize = () => {
            if (canvas.parentElement) {
                canvas.width = canvas.parentElement.clientWidth;
                canvas.height = canvas.parentElement.clientHeight;
            }
        };
        //add a listener to handle resizing whenever the window is resized
        window.addEventListener('resize', handleResize);

        //when the component unmounts, cancel the animation and stop listening for resizes
        return () => {
            window.cancelAnimationFrame(animationFrameId)
            window.removeEventListener('resize', handleResize)
        }
    }, [draw])
    
    //this component returns a single canvas element
    return <canvas ref={canvasRef} {...rest}/>
    }
    export default Canvas
`;

  return (
    <SyntaxHighlighter language="javascript" style={synthwave84} customStyle={{ fontSize: '0.75vw' }}>
      {codeString}
    </SyntaxHighlighter>
  );
}

export default CanvasCode;