import React, { useState } from "react";
import "./Home.css"
import AnimatedBackground from "../../Components/AnimatedBackgroundShowcase/AnimatedBackground";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import hyperdodecahedron from "../../Animation/hyperdodecahedronLargeDepth";
import hyperdodecahedron_small from "../../Animation/hyperdodecahedron";
import { useNavigate } from 'react-router-dom';
import Canvas from "../../Components/Canvas/Canvas";

const Home=(props)=>{

    const [contentHidden,hideContent] = useState(false)
    
    const naviagtor=useNavigate()

    return(
        <div class="page">
        <Header hideContent={hideContent} contentHidden={contentHidden} />
        <AnimatedBackground animation={hyperdodecahedron} 
        content={
            <main class="home" style={{visibility:contentHidden?"hidden":"visible"}}>
                <div class="grid-wrapper">
                    <section class="wide">
                        <div class='left_align'>
                            <span onClick={()=>{naviagtor("animated_background")}} class="extra_large ">Hello World!</span>
                            <span class="large">I am Jozef, a Fontend Designer 🎨, Developer 🧑‍💻, and Engineer 👷‍♂️.</span>
                        </div>
                    </section>
                    <section class="single">
                        <img class="headshot" src={require("../../Assets/pfp.jpg")} alt="Jozef Janosko, City Hall, Philedelphia"/>
                    </section>
                    <section class="full padded gapped">
                        <span class="header">Welcome To My Portfolio</span>
                        <span  class="large">This website is meant to serve as a showcase for my skills and my projects. I want this site to show what I'm capable of, and to reflect my growth as I continue on my journey as a developer. <span>Stay Tuned!</span> There's a lot more to come...</span>
                    </section>
                    <section class="double">
                        <span  class="horizontal-scroll-container large"><p class='horizontal-scrolling-text-left'>Design - Create - Optimize - Design - Create - Optimize - Design - Create - Optimize - Design - Create - Optimize - </p><p class='horizontal-scrolling-text-left'>Design - Create - Optimize - Design - Create - Optimize - Design - Create - Optimize - Design - Create - Optimize - </p></span>
                        <span class="horizontal-scroll-container large"><p class='horizontal-scrolling-text-right'>Design - Create - Optimize - Design - Create - Optimize - Design - Create - Optimize - Design - Create - Optimize - </p><p class='horizontal-scrolling-text-right'>Design - Create - Optimize - Design - Create - Optimize - Design - Create - Optimize - Design - Create - Optimize - </p></span>
                        <span class="horizontal-scroll-container large"><p class='horizontal-scrolling-text-left'>Design - Create - Optimize - Design - Create - Optimize - Design - Create - Optimize - Design - Create - Optimize - </p><p class='horizontal-scrolling-text-left'>Design - Create - Optimize - Design - Create - Optimize - Design - Create - Optimize - Design - Create - Optimize - </p></span>
                    </section>
                    <section class="tall double padded gapped">
                        <span class="header">About Me</span>
                        <span class="large"><br/>I'm a  developer with full-stack experience building web and mobile applications. I have experience with modern development libraries, such as react and bootstrap.</span>
                        <span class="large"><br/>When the internet is down, you can find me outdoors. I enjoy hiking 🥾, exploring, and picking up cool rocks 🪨. I also like cooking 🍲, playing games, and coffee ☕.</span>
                        <span class="large"><br/>Above all else, I like to learn, grow, and be challenged.</span>
                    </section>
                    <section  class="double subgrid padded">
                        <div class="single">
                            <span class="header">Page Background</span><br/>
                            <span class="large">The background of this page is a homemade animation called hyperdodecahedronLargeDepth. </span><br/>
                            <span class="large">Many pages on this website are animated. You can get a better look at the background by selecting "hide content" in the page settings.</span>
                        </div>
                        <div class="single">
                            <div class='canvas_wrapper'>
                                <Canvas draw={hyperdodecahedron_small} />
                            </div>    
                        </div>
                    </section>
                    <section class="full">
                        <div class="popup_word_container">
                            <span class='extra_large popup_word pop1'>More</span>
                            <span class='extra_large popup_word pop2'>Coming</span>
                            <span class='extra_large popup_word pop3'>Soon</span>
                        </div>
                    </section>
                </div>
            </main>
        }
        
         />
         <Footer/>
        </div>
    );
}
export default  Home;