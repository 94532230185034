const hyperdodecahedron = (ctx, frameCount) => {
    // Simplified set of vertices representing part of a 120-cell
    const generate120CellVertices = () => {
        const vertices = [];
        const phi = (1 + Math.sqrt(5)) / 2; // Golden ratio
        
        // Permutations of (±2, 0, 0, 0)
        for (const x of [2, -2]) vertices.push([x, 0, 0, 0]);
        for (const y of [2, -2]) vertices.push([0, y, 0, 0]);
        for (const z of [2, -2]) vertices.push([0, 0, z, 0]);
        for (const w of [2, -2]) vertices.push([0, 0, 0, w]);
    
        // Permutations of (±1, ±1, ±1, ±1)
        for (const x of [1, -1]) {
            for (const y of [1, -1]) {
                for (const z of [1, -1]) {
                    for (const w of [1, -1]) {
                        vertices.push([x, y, z, w]);
                    }
                }
            }
        }
    
        // 96 vertices of (±φ, ±1, ±1/φ, 0) permutations
        for (const a of [phi, -phi]) {
            for (const b of [1, -1]) {
                for (const c of [1 / phi, -1 / phi]) {
                    vertices.push([a, b, c, 0]);
                    vertices.push([a, 0, b, c]);
                    vertices.push([0, a, b, c]);
                }
            }
        }
    
        // This returns an array of 600 vertices in 4D.
        return vertices;
    };
    
    const generate120CellEdges = (vertices) => {
        const edges = [];
        const distanceThreshold = 2; // Define a threshold for edge connection
    
        // Iterate over pairs of vertices to form edges
        for (let i = 0; i < vertices.length; i++) {
            for (let j = i + 1; j < vertices.length; j++) {
                const d = Math.sqrt(
                    (vertices[i][0] - vertices[j][0]) ** 2 +
                    (vertices[i][1] - vertices[j][1]) ** 2 +
                    (vertices[i][2] - vertices[j][2]) ** 2 +
                    (vertices[i][3] - vertices[j][3]) ** 2
                );
                if (Math.abs(d - distanceThreshold) < 1e-5) {
                    edges.push([i, j]);
                }
            }
        }
    
        // This returns an array of edges for the 120-cell.
        return edges;
    };
    
    // Generate the vertices and edges
    const cell120Vertices = generate120CellVertices();
    const cell120Edges = generate120CellEdges(cell120Vertices);

    // Project 4D to 3D, then 3D to 2D
    const project4D = (point, angleXY, angleXZ, angleYW, distance) => {
        const [x, y, z, w] = point;

        // Apply rotation matrices
        const cosXY = Math.cos(angleXY), sinXY = Math.sin(angleXY);
        const cosXZ = Math.cos(angleXZ), sinXZ = Math.sin(angleXZ);
        const cosYW = Math.cos(angleYW), sinYW = Math.sin(angleYW);

        // Rotate in XY plane
        let x1 = x * cosXY - y * sinXY;
        let y1 = x * sinXY + y * cosXY;

        // Rotate in XZ plane
        let x2 = x1 * cosXZ - z * sinXZ;
        let z1 = x1 * sinXZ + z * cosXZ;

        // Rotate in YW plane
        let y2 = y1 * cosYW - w * sinYW;
        let w1 = y1 * sinYW + w * cosYW;

        // 4D to 3D projection
        const scale3D = distance / (distance + w1);
        const x3d = x2 * scale3D;
        const y3d = y2 * scale3D;
        const z3d = z1 * scale3D;

        //scale factor
        const scaleFactor=40

        // 3D to 2D projection
        const scale2D = distance / (distance + z3d);
        const x2d = x3d * scale2D * scaleFactor + ctx.canvas.width / 2;
        const y2d = y3d * scale2D * scaleFactor + ctx.canvas.height / 2;

        return [x2d, y2d];
    };
    ctx.strokeStyle = '#000000';
    ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    ctx.strokeStyle = '#FF0000';

    // Rotation angles
    const angleXY = frameCount * 0.001;
    const angleXZ = frameCount * 0.0008;
    const angleYW = frameCount * 0.001;
    const distance = 1000;

    // Draw edges
    ctx.beginPath();
    cell120Edges.forEach(([start, end]) => {
        const [x1, y1] = project4D(cell120Vertices[start], angleXY, angleXZ, angleYW, distance);
        const [x2, y2] = project4D(cell120Vertices[end], angleXY, angleXZ, angleYW, distance);
        ctx.moveTo(x1, y1);
        ctx.lineTo(x2, y2);
    });
    ctx.stroke();
};

export default hyperdodecahedron;