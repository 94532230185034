import "./Footer.css"
import React from "react";

const Footer = (props)=>{
    return(
        <footer>
            <div>
            </div>
            <div>
                <p>© 2024 Jozef Janosko</p>
            </div>
            <div>

            </div>
        </footer>
    )
}
export default Footer