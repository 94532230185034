const nightsky = (ctx, frameCount) => {

    //calculates the percentage (as a decimal) of the screen the user has scrolled down
    let scrollProgress = window.scrollY/(document.documentElement.scrollHeight-window.innerHeight);

    //if the screen has no overflow, return 0.0001 (no animation visible)
    if(isNaN(scrollProgress)|| !scrollProgress)
        scrollProgress=0.0001;
    
    //gradient variables
    let gradient = ctx.createLinearGradient(0, 0, 0, ctx.canvas.height);
    
    const dusk1 = '#000000';
    const dusk2 = '#000015';
    const midnight1 = '#131035'
    const midnight2 = '#302435';

    //calculates the value for the gradient colors (shifts from a dusk sky to a midnight sky, and then to dawn (same as dusk))
    gradient.addColorStop(0, interpolateColor(dusk1, midnight1,scrollProgress));
    gradient.addColorStop(0.5, interpolateColor(dusk2, midnight2,scrollProgress));


    // Set the fill style and draw a rectangle
    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);



    //moon variables
    const theta = 0 - Math.PI * scrollProgress;     //we're getting polar in here
    //console.log(window.scrollY/(document.documentElement.scrollHeight-window.innerHeight))
    let moonX=(window.innerWidth)* scrollProgress;                   //x coordinate of moon relative to top left (origin)
    let moonY=(window.innerHeight/2) + (window.innerHeight/6) *Math.sin(theta);                   //7 coordinate of moon relative to top left (origin)
    let radius=ctx.canvas.width*0.05

    // draws a gradient for a shaded moon
    gradient = ctx.createRadialGradient(moonX, moonY, radius * 0.1, moonX, moonY, radius);
    gradient.addColorStop(0, '#FEC'); // Lighter at the center
    gradient.addColorStop(1, '#FE9'); // Darker towards the edges
    ctx.fillStyle = gradient;
    ctx.beginPath();
    ctx.arc(moonX, moonY, radius, 0, Math.PI * 2, false);
    ctx.fill();
    ctx.closePath();

    
    // Set mountain color
    const dusk_mountain = '#282828';
    const midnight_mountain = '#6A6A6A';
    ctx.fillStyle = interpolateColor(midnight_mountain,dusk_mountain, scrollProgress)

    ctx.beginPath();
    ctx.moveTo(0, ctx.canvas.height); // Start from bottom left corner
    ctx.lineTo(0, ctx.canvas.height*0.9);//base 0
    ctx.lineTo(ctx.canvas.width * 0.2, ctx.canvas.height * 0.7); // Peak 1
    ctx.lineTo(ctx.canvas.width * 0.33, ctx.canvas.height*0.8); // Base 1
    ctx.lineTo(ctx.canvas.width * 0.5, ctx.canvas.height * 0.6); // Peak 2
    ctx.lineTo(ctx.canvas.width * 0.66, ctx.canvas.height*0.8); // Base 2
    ctx.lineTo(ctx.canvas.width*0.8, ctx.canvas.height * 0.7); // Peak 3
    ctx.lineTo(ctx.canvas.width, ctx.canvas.height*0.9); // Base 3
    ctx.lineTo(ctx.canvas.width, ctx.canvas.height); // Base to the right corner
    ctx.closePath();
    ctx.fill();

    //secondary mountain range
    const dusk_mountain2 = '#353535';
    const midnight_mountain2 = '#808080';
    ctx.fillStyle = interpolateColor(midnight_mountain2,dusk_mountain2, scrollProgress)

    ctx.beginPath();
    ctx.moveTo(0, ctx.canvas.height); // Start from bottom left corner
    ctx.lineTo(0, ctx.canvas.height*0.85);//base 0
    ctx.lineTo(ctx.canvas.width * 0.05, ctx.canvas.height * 0.8); // Peak 1
    ctx.lineTo(ctx.canvas.width * 0.15, ctx.canvas.height*0.9); // Base 1
    ctx.lineTo(ctx.canvas.width * 0.3, ctx.canvas.height * 0.7); // Peak 2
    ctx.lineTo(ctx.canvas.width * 0.5, ctx.canvas.height*0.9); // Base 2
    ctx.lineTo(ctx.canvas.width*0.65, ctx.canvas.height * 0.75); // Peak 3
    ctx.lineTo(ctx.canvas.width*0.75, ctx.canvas.height*0.9); // Base 3
    ctx.lineTo(ctx.canvas.width*0.9, ctx.canvas.height * 0.75); // Peak 4
    ctx.lineTo(ctx.canvas.width, ctx.canvas.height*0.85); // Base 4
    ctx.lineTo(ctx.canvas.width, ctx.canvas.height); // Base to the right corner
    ctx.closePath();
    ctx.fill();
  }
  export default nightsky

function interpolateColor(color1, color2, scrollProgress){

    const shiftValue = scrollProgress <= 0.5 ? (scrollProgress * 2) : (2 - scrollProgress * 2);

    const hexToRgb = (hex) => {
        let bigint = parseInt(hex.slice(1), 16);
        let r = (bigint >> 16) & 255;
        let g = (bigint >> 8) & 255;
        let b = bigint & 255;
        return [r, g, b];
      };
    
      const rgb2 = hexToRgb(color1);
      const rgb1 = hexToRgb(color2);
    
      const resultRgb = rgb1.map((val, i) => {
        return Math.round(val + shiftValue * (rgb2[i] - val));
      });
    
      return `rgb(${resultRgb[0]}, ${resultRgb[1]}, ${resultRgb[2]})`;
}